/*eslint no-undef: 0 react/no-render-return-value: 0 */
import commonUtils from 'commonUtils';
import _ from 'lodash';
import paramUtils from 'paramUtils';
import React from 'react';
import { createRoot } from 'react-dom/client';

import LoginElement from './components/LoginElement.jsx';
import './login.scss';

window.globals = {
  versions: _.toPlainObject(document.getElementById('login-script').dataset),
  config: commonUtils.getDefaultGlobalConfig(),
};

function renderElement(element) {
  const container = document.getElementById('main');
  if (!window.root) {
    window.root = createRoot(container);
  }
  window.root.render(element);
}

localStorage.removeItem('csrf');

window.addEventListener('load', function () {
  commonUtils.initGlobalConfig(() => {
    let params = paramUtils.parseParams('?', location.search);
    let MainLoginComponent = React.createElement(LoginElement, { params });
    renderElement(MainLoginComponent);
  });
});
